import { useHistory } from "react-router-dom";
import { yieldToMain } from "../utils/helpers/yield-to-main";

const useCustomHistoryOperations = () => {
    const history = useHistory();

    const customPush = async(relativeUrl, prev) => {
        await yieldToMain();
        // Push the route using relativeUrl
        history.push(relativeUrl, prev ? prev : null);
    };

    const customReplace = async(relativeUrl) => {
        await yieldToMain();
        // Replace the route using relativeUrl
        history.replace(relativeUrl);
    };

    const customGoBack = async () => {
        await yieldToMain();
        // Replace the route using relativeUrl
        history.goBack();
    };

    return {customPush, customReplace, customGoBack};
};

export default useCustomHistoryOperations;
